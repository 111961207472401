












































import Vue from "vue";

import { moreNavLinks } from "./nav-links";

export default Vue.extend<any, any, any, any>({
  name: "Drawer",
  components: {
    List: () =>
      import(
        /* webpackChunkName: "list" */
        "./List.vue"
      ),
  },
  data: () => ({
    dark: true,
    items: moreNavLinks,
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.getters.drawer;
      },
      set(val) {
        this.$store.dispatch("setDrawer", val);
      },
    },
  },
  methods: {
    toggleMini(mini: boolean) {
      this.$store.dispatch("toggleMini", mini);
    },
    toggleExpanded(): void {
      this.$store.dispatch("toggleMini", false);
      this.$store.dispatch("expandSidebar", false);
    },
  },
  mounted() {
    //
  },
});
